import React, { useState } from "react";
import BackSlider from "../../components/Login/BackSlider";
import CardRound from "../../components/Login/CardRound";
import bg1 from "../../../src/images/login/bgs/bg-1.jpg";
import bg2 from "../../../src/images/login/bgs/bg-2.jpg";
import { Link } from "gatsby";
import ReCAPTCHA from "react-google-recaptcha";
import { FaTimes } from "react-icons/fa";
import { Formik, ErrorMessage, Field } from "formik";

import api from "../../services/api";
import requests from "../../services/requests";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../components/Login/Loading";

const yup = require("yup");

const schema = yup.object().shape({
    password: yup.string().min(6, "A senha deve conter no mínimo 6 caracteres").required("Campo obrigatório"),
    username: yup.string().email("E-mail inválido").required("Campo obrigatório"),
});

const Login = ({ email, auth }) => {
    const [showCaptcha, setShowCaptcha] = useState(false);
    const recaptchaRef = React.createRef();
    const [isLoading, setisLoading] = useState(auth);

    const onSubmit = async (values, { setFieldError, setSubmitting }) => {
        setSubmitting(true);
        setisLoading(true);
        api.post("/login/check", values)
            .then(resLogin => {
                var token = resLogin.data.token;
                window.localStorage.setItem("session-token", token);

                let profile = requests.getProfile().then(profile => {
                    window.localStorage.setItem("profile-role", profile.role);
                    window.localStorage.setItem("profile-identifier", profile.identifier);
                    window.localStorage.setItem("profile-name", profile.name);
                    window.localStorage.setItem("profile-email", profile.email);
                    window.localStorage.setItem("profile-cellphone", profile.cellphone);
                    window.localStorage.setItem("profile-phone", profile.phone);
                    window.localStorage.setItem("profile-role-human-readable", profile.roleHumanReadable);
                    window.localStorage.setItem("profile-picture", profile.picture);
                    window.localStorage.setItem("profile-permissions", JSON.stringify(profile.permissions));
                    
                    requests.listProcessPriority().then(data => {
                        var priorities = [];
                        for (let i in data) {
                            priorities.push(data[i].identifier);
                        }
                        window.localStorage.setItem("profile-process-priority", JSON.stringify(priorities));
                    });
                    setSubmitting(false);
                    
                    {
                        profile.role === "ROLE_DRIVER"
                        ? toast.error("Usuário sem permissão de acesso", localStorage.clear(), setisLoading(false))
                        : window.location.href = "/dashboard";
                    }

                    if (window.localStorage.getItem("redirect_to")) {
                        const redirect = window.localStorage.getItem("redirect_to");
                        window.localStorage.removeItem("redirect_to");
                        if (redirect !== '/assinatura/') {
                            window.location.href = redirect;
                            return;
                        }
                    }

                    return;
                });
            })
            .catch(error => {
                if (error.response?.data?.message === "INVALID_RECAPTCHA") {
                    setShowCaptcha(true);
                } else if (error.response?.status === 401) {
                    if (error.response.data.message === "Invalid credentials.") {
                        setFieldError("password", "Combinação de e-mail e senha inválida.");
                    } else {
                        setFieldError("password", error.response.data.message);
                    }
                } else {
                    setFieldError("password", "Algo deu errado, tente novamente mais tarde.");
                }
                setSubmitting(false);
                setisLoading(false);
            })
        return false;
    };

    const bgs = [
        {
            urlBg: bg1,
            text: "Vendemmia Logística Integrada",
            timeSecs: 5,
            topicId: 1, // 1- Navio, 2 - Caminhão, 3 - Armazém, null - Ocultar
            filter: 0, // 0 - Desativado, qualquer outro valor é o brilho do filtro. (Atualmente desativado)
        },
        {
            urlBg: bg2,
            text: "Pessoas e Negócios conectados",
            timeSecs: 5,
            topicId: 2,
            filter: 0,
        },
    ];
    return (
        <BackSlider slides={bgs}>
            <CardRound>
                {!auth ? (
                    <Formik
                        initialValues={{
                            username: "",
                            password: "",
                        }}
                        validationSchema={schema}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                            >
                                <div className="field">
                                    <label htmlFor="username">E-mail</label>
                                    <Field
                                        id="username"
                                        type="text"
                                        name="username"
                                        autoComplete="email"
                                        placeholder="Digite seu Email"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="text-red mt-1 font-light text-xs"
                                        name="username"
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="password">Senha</label>
                                    <Field
                                        id="password"
                                        type="password"
                                        name="password"
                                        autoComplete="password"
                                        placeholder="Digite sua Senha"
                                    />
                                    <ErrorMessage
                                        component="p"
                                        className="text-red mb-4 mt-1 font-light text-xs"
                                        name="password"
                                    />
                                </div>
                                {showCaptcha && (
                                    <div className="modal-captcha h-100vh">
                                        <div className="captcha m-auto p-4 text-center align-middle">
                                            <div
                                                className="cursor-pointer m-2 float-right"
                                                onClick={() => {
                                                    setShowCaptcha(false);
                                                }}
                                            >
                                                <FaTimes />
                                            </div>
                                            <ReCAPTCHA
                                                style={{ minHeight: "80px" }}
                                                className="mt-4 mb-2 g-recaptcha"
                                                ref={recaptchaRef}
                                                name="recaptcha_token"
                                                sitekey={process.env.REACT_APP_SITE_KEY}
                                                onChange={response => {
                                                    setFieldValue("recaptcha_token", response);
                                                    setShowCaptcha(false);
                                                    handleSubmit();
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="flex justify-between w-full mt-6">
                                    <Link className="remember" to="/recover/" state={{ email: values.username }}>
                                        Esqueci minha senha
                                    </Link>
                                    {!isSubmitting ? (
                                        <button
                                            type="submit"
                                            className="btn"
                                            disabled={values.password?.length < 6 || values.username?.length < 6}
                                        >
                                            Entrar
                                        </button>
                                    ) : (
                                        <>Carregando...</>
                                    )}
                                </div>
                            </form>
                        )}
                    </Formik>
                ) : (
                    <h1>Validando acesso...</h1>
                )}
            </CardRound>
            <ToastContainer />
            <Loading status={isLoading || auth} />
        </BackSlider>
    );
};

export default Login;
